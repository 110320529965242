@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-gray-800;
  }
  button,
  input,
  textarea,
  select {
    @apply focus:outline-none;
  }
}

.no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

@layer components {
  .absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .avatar {
    @apply inline-block rounded-full object-cover;
  }
  .avatar-default {
    @apply inline-block overflow-hidden rounded-full bg-gray-100 align-middle;
  }
  .button {
    @apply h-14 rounded-xl px-4;
  }
  .card {
    @apply flex flex-col divide-y divide-gray-200 overflow-hidden rounded-lg border border-gray-200 shadow;
  }
  .checkbox {
    @apply wh-6 rounded border-0 bg-gray-100 text-gray-900
           focus:outline-none focus:ring-0;
  }

  .dropdown {
    @apply relative w-max;
  }
  .dropdown-view {
    @apply absolute z-10 mt-2 w-max origin-top transform bg-white shadow-lg ring-1
           ring-black ring-opacity-5 transition focus:outline-none;
  }

  h1 {
    @apply text-32 md:text-48;
  }

  h4 {
    @apply text-24;
  }

  h5 {
    @apply text-20;
  }

  h6 {
    @apply text-17;
  }

  .label {
    @apply text-sm text-gray-800;
  }
  .label-col {
    @apply flex flex-col space-y-1;
  }
  .label-row {
    @apply flex items-center space-x-2;
  }
  .menu {
    @apply flex flex-col py-1;
  }
  .menu-item {
    @apply px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-800;
  }
  .pagination {
    @apply flex items-center justify-between px-6 py-3;
  }
  .pagination-label {
    @apply text-sm text-gray-700;
  }
  .pagination-nav {
    @apply flex -space-x-px shadow-sm;
  }
  .pagination-nav-button {
    @apply grid min-w-10 place-items-center border p-2 text-sm font-medium;
  }
  .peer-label {
    @apply absolute left-0 -top-7 text-sm text-gray-600 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:px-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-7 peer-focus:px-0 peer-focus:text-sm peer-focus:text-gray-600;
  }
  .peer-textfield {
    @apply h-14 w-full rounded-xl border border-gray-200 px-4 text-gray-900 placeholder-transparent focus:border-brand-2 focus:outline-none focus:ring-0;
  }

  .progress[value]::-webkit-progress-bar {
    @apply overflow-hidden rounded-full bg-gray-200;
  }

  .progress[value] {
    appearance: none;
  }

  .progress[value]::-webkit-progress-value {
    @apply bg-green-500;
  }

  .radio {
    @apply wh-4 text-brand-1
           focus:outline-none focus:ring-2 focus:ring-brand-1 focus:ring-offset-2;
  }
  .select {
    @apply h-14 rounded-xl border border-gray-200  placeholder-gray-400
           focus:border-brand-2 focus:ring-0 disabled:bg-gray-100 disabled:text-gray-400;
  }
  .sidebar {
    @apply flex w-64 flex-col border-r border-gray-200;
  }
  .sidebar-title {
    @apply flex h-16 items-center px-4 text-2xl font-bold text-gray-800;
  }
  .sidebar-menu {
    @apply flex flex-grow flex-col space-y-1 overflow-y-auto p-2;
  }
  .sidebar-menu-item {
    @apply flex items-center rounded-md p-2 text-sm font-medium;
  }
  .table {
    @apply divide-y divide-gray-200;
  }
  .table-head {
    @apply bg-gray-50;
  }
  .table-body {
    @apply divide-y divide-gray-200;
  }
  .table-th {
    @apply px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500;
  }
  .table-td {
    @apply max-w-xs truncate px-6 py-4 text-left text-sm font-normal text-gray-500;
  }
  .textarea {
    @apply rounded-md border border-gray-200 px-4 py-2 placeholder-gray-400
           focus:border-brand-1 focus:ring-0 disabled:bg-gray-100 disabled:text-gray-400;
  }

  .textfield {
    @apply h-14 rounded-lg border border-gray-200 px-4 placeholder-gray-400
           focus:border-brand-1 focus:ring-0 disabled:bg-gray-100 disabled:text-gray-400;
  }

  .textfield::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .textfield::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .toggle {
    @apply relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2
           border-transparent transition-colors duration-200 ease-in-out focus:outline-none
           focus:ring-2 focus:ring-brand-1 focus:ring-offset-2;
  }
  .toggle-slider {
    @apply pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow
           ring-0 transition duration-200 ease-in-out;
  }
}
